@import url('https://fonts.cdnfonts.com/css/bookman-old-style');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* =============================Basic================================ */

section, h1, h4 {
  padding: 5px 20%;
  text-align: left;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 31px;
  color: #BF0A30;
  border-bottom: 1px solid #BF0A30;
}

h3 {
  font-size: 27px;
}

p, dl {
  font-size: 20px;
  font-family: "TimesNewRoman";
}

ul li:not(:last-child){
  margin-bottom: 5px;
}

h1, h2, h3, h4 { 
  font-family: "Bookman Old Style";
}

h4 {
  font-size: 25px;
}

dl {
  display: grid;
  grid-template-columns: max-content auto;
}

dt {
  grid-column-start: 1;
  border-bottom: 2px dotted #002868;
  margin-bottom: 5px;
  margin-bottom: 10px;
}

dd {
  grid-column-start: 2;
  margin-bottom: 5px
}

/* ===========================Footer======================= */

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}